<template>
    <v-container class="pb-16" :class="isDesktop?'pt-6':'pt-0'">
        <v-row>
            <v-col cols="12">
                <v-card elevation="24"
                        :href="hero.link"
                        target="_blank"
                        link
                        :class="isDesktop?'flex-nowrap':'flex-wrap'"
                        :style="{ backgroundImage: 'url(' + require('@/assets/bg/' + hero.key + '_bg.png') + ')' }"
                        class="text-decoration-none d-flex align-items-stretch overflow-hidden">
                    <label class="pa-4 rounded-0"
                           :class="blurryClass"
                           :style="isDesktop?'flex-basis: 50%;':'flex-basis: 100%; order: 2'">
                        <v-card-subtitle class="rounded-0 mb-0 pb-0">
                            <v-chip x-small
                                    color="primary"
                                    class="mb-2">Sponsored
                            </v-chip>
                        </v-card-subtitle>
                        <v-card-title class="rounded-0 mt-0 pt-0 mb-4"
                                      style="word-break: break-word">
                            <div class="mt-2">
                                {{ hero.title }}
                            </div>
                        </v-card-title>
                        <v-card-subtitle class="rounded-0">
                            {{ hero.desc }}
                        </v-card-subtitle>
                        <v-card-actions>
                            <v-btn text
                                   :color="isDark?'white':'primary'"
                                   style="text-transform: none">
                                {{ $t('เข้าชม') }} {{ hero.chip }}
                                <v-icon color="primary"
                                        small
                                        class="ml-2">mdi-arrow-right
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </label>
                    <v-carousel show-arrows-on-hover
                                hide-delimiters
                                :show-arrows="false"
                                class="rounded-0"
                                height="auto">
                        <div class="video-container">
                            <iframe :src="hero.video"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                        </div>
                    </v-carousel>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-card elevation="24"
                        link
                        :class="this.$vuetify.theme.dark?'grey darken-3':'grey lighten-5'"
                        :to="'notifications'"
                        class="pa-4 text-decoration-none d-flex justify-space-between flex-nowrap">
                    <div>
                        <v-card-title>{{ $t('รับการแจ้งเตือน') }}</v-card-title>
                        <v-card-text>{{ $t('ติดตามและจัดการการแจ้งเตือนล่าสุดของคุณเพื่อไม่พลาดข้อมูลสำคัญเกี่ยวกับการสั่งซื้อและการจัดส่งสินค้า') }}</v-card-text>
                        <v-card-actions>
                            <v-btn text
                                   color="primary">{{ $t('รับการแจ้งเตือน') }}
                                <v-icon color="primary"
                                        small
                                        class="ml-2">mdi-arrow-right
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </div>
                    <div  :style="isDesktop?'max-width: 150px':'max-width: 100px'">
                        <v-img placeholder
                               :src="require('@/assets/sticker/receive_notify.png')"></v-img>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="6">
                <v-card elevation="24"
                        link
                        :class="this.$vuetify.theme.dark?'grey darken-3':'grey lighten-5'"
                        href="https://lnw.me/account/manage"
                        class="pa-4 text-decoration-none d-flex justify-space-between flex-nowrap">
                    <div>
                        <v-card-title>
                            <div class="line-clamp-1">{{ $t('ตั้งค่าบัญชี') }} {{ userData.name }}</div></v-card-title>
                        <v-card-text>{{ $t('จัดการข้อมูลส่วนตัว ความเป็นส่วนตัว และความปลอดภัยของคุณได้ง่ายๆ เพื่อให้การใช้งานบัญชีของคุณดีที่สุด') }}</v-card-text>
                        <v-card-actions>
                            <v-btn text
                                   color="primary">{{ $t('ตั้งค่าบัญชี') }}
                                <v-icon color="primary"
                                        small
                                        class="ml-2">mdi-arrow-right
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </div>
                    <div :style="isDesktop?'max-width: 150px':'max-width: 100px'">
                        <v-img placeholder
                               class="rounded-circle"
                               :class="isDesktop?'ma-4':'ma-4'"
                               :src="userData.avatar"></v-img>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   class="mt-6 pb-0">
                <div class="just_flex">
                    <h2 class="text-h5 font-weight-bold mb-0">{{ $t('ร้านค้าโปรดของฉัน') }}
                        <v-chip small
                                v-if="favShops"
                                class="ml-1">
                            {{ favShops.length ? favShops.length : '0' }}
                        </v-chip>
                    </h2>
                    <v-btn color="primary"
                           small
                           to="favourite"
                           text>{{ $t("ทั้งหมด") }}
                    </v-btn>
                </div>
            </v-col>
            <v-row class="flex-nowrap"
                   style="overflow-x: scroll; padding: 32px 16px;" :style="isDesktop?'margin: 0 -48px 0 -12px;':'margin: 0 -12px;'">
                <v-col v-for="shop in favShops"
                       :key="shop.shop_id"
                       cols="12"
                       class="d-flex align-stretch p-0 custom-width-col ">
                    <ShopCard :shop="shop"></ShopCard>
                </v-col>
            </v-row>
        </v-row>
        <v-row v-if="myShops != null">
            <v-col cols="12"
                   class="mt-6 pb-0">
                <div class="just_flex">
                    <h1 class="text-h5 font-weight-bold mb-0">{{ $t('ร้านค้าของฉัน') }} <v-chip v-if="myShops" class="ml-1" small>{{ (myShops.length?myShops.length:'0')}}</v-chip></h1>
                    <v-btn color="primary"
                           small
                           to="shops"
                           text>{{ $t("ทั้งหมด") }}
                    </v-btn>
                </div>
            </v-col>
            <v-row class="flex-nowrap"
                   style="overflow-x: scroll; padding: 32px 16px;" :style="isDesktop?'margin: 0 -48px 0 -12px;':'margin: 0 -12px;'">
                <v-col v-for="shop in myShops"
                       :key="shop.shop_id"
                       cols="12"
                       class="d-flex align-stretch p-0 custom-width-col ">
                    <ShopCard :shop="shop" :my_shop="true"></ShopCard>
                </v-col>
            </v-row>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h2 class="text-h5 font-weight-bold mb-0">{{ $t('เมนูทางลัด') }}</h2>
                <h4 style="font-weight: bold; text-align: left;"></h4>
                <ul class="p-0 m-0 mt-4"
                    style="list-style: none; line-height: 2.5">
                    <li><a href="https://lnw.me/account/manage/main/profile#topic-username">{{ $t('เปลี่ยน Username') }}</a></li>
                    <li><a href="https://lnw.me/account/manage/main/security#topic-password">{{ $t('เปลี่ยน รหัสผ่าน') }}</a></li>
                    <li>
                        <a href="https://lnw.me/account/logout?continue_url=https%3A%2F%2Flnw.me%2Faccount%2Fmanage%2Fmain%2Fhome">{{ $t('ออกจากระบบ') }}</a>
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import {mapState} from "vuex";
import ShopCard from "@/components/App/components/ShopCard";

export default {
    name: "HomePage",
    mixins: [
        MixinsHelper,
    ],
    components: {
        ShopCard
    },
    data(){
        return {
            randomValue: Math.floor(Math.random() * 2)
        };
    },
    computed: {
        ...mapState({
            userData: state => state.user.userData,
            favShops: state => state.user.favShops,
            myShops: state => state.user.myShops,
        }),
        hero(){
            return this.heroes[this.randomValue];
        },
        heroes(){
            return [
                {
                    key: 'lnwshop',
                    link: 'https://www.lnwshop.com',
                    chip: 'LnwShop',
                    title: 'เทพช็อป #1 แพลทฟอร์มออนไลน์สำหรับทุกคน',
                    desc: 'ขายของออนไลน์ได้ทุกช่องทางผ่านเทพช็อป เปิดเว็บร้านของคุณเอง. เชื่อมต่อมาร์เก็ตเพลส. ขายผ่านโซเชียล. จัดการทุกอย่างง่ายในที่เดียว',
                    video: this.isLocalhost?'':"https://www.youtube.com/embed/hlbmIHBhGTM?autoplay=1&mute=1&controls=0&rel=0&loop=1&hl=en&cc_load_policy=0&cc_lang_pref=en",
                },
                {
                    key: 'lnwshop_pro',
                    link: 'https://www.lnwshop.com/pro',
                    chip: 'LnwShop Pro',
                    title: this.$t('แพลทฟอร์มออนไลน์สำหรับธุรกิจองค์กร'),
                    desc: this.$t('LnwShop Pro เท่านั้นที่ออกแบบมาเพื่อช่วยให้คุณบริหารธุรกิจได้ง่ายที่สุด ก้าวสู่ออนไลน์กับเราได้อย่างรวดเร็ว รับจำนวนจำกัด'),
                    video: this.isLocalhost?'':"https://www.youtube.com/embed/-JdmDrL7bBg?autoplay=1&mute=1&controls=0&rel=0&loop=1&hl=en&cc_load_policy=0&cc_lang_pref=en",
                }
            ];
        }
    }
};
</script>
<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Default 1:1 Aspect Ratio */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.8); /* Zoom in to crop the video and remove black bars */
  transform-origin: center; /* Ensure cropping is evenly centered */
  pointer-events: none; /* Disable interaction to prevent accidental zoom/pan */
}

@media (min-width: 600px) {
  .video-container {
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio for larger screens */
  }

  .video-container iframe {
    transform: scale(1); /* Reset scaling for larger screens */
  }
}

.custom-width-col {
  width: 100%;
}
@media (min-width: 0px) {
  /* Small screens (sm): Override col-sm-6 */
  .custom-width-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90% !important; /* Change this value as needed */
    flex: 0 0 90% !important;
    max-width: 90% !important;
  }
}

@media (min-width: 600px) {
  /* Medium screens (md): Override col-md-4 */
  .custom-width-col {
    -ms-flex: 0 0 60% !important; /* Change this value as needed */
    flex: 0 0 60% !important;
    max-width: 640% !important;
  }
}
@media (min-width: 960px) {
  /* Medium screens (md): Override col-md-4 */
  .custom-width-col {
    -ms-flex: 0 0 40% !important; /* Change this value as needed */
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
}

@media (min-width: 1280px) {
  .custom-width-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30% !important; /* Change this value as needed */
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}

@media (min-width: 1600px) {
  .custom-width-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22.22% !important; /* Change this value as needed */
    flex: 0 0 22.22% !important;
    max-width: 22.22% !important;
  }
}
</style>